import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { RESTGetAPIBalancesResult } from "types/points";

const getLeaderboard = async (token: string, guildID: string, page: number) => {
  return api.get<RESTGetAPIBalancesResult | undefined>(
    `/guilds/${guildID}/leaderboard?page=${page - 1}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const useLeaderboard = (guildID?: string, page: number = 1) => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["leaderboard", guildID, page],
    queryFn: async () => {
      if (!token || !guildID) return undefined;
      const res = await getLeaderboard(token, guildID, page);
      return res.data;
    },
    enabled: !!token && !!guildID,
    placeholderData: keepPreviousData,
  });
};
