import { FC, useEffect, useState } from "react";
import { api } from "config/network";
import {
  APIBackpack,
  APIReward,
  APIPremium,
  APIBalance,
  APIMember,
  APIUser,
  APIGuild,
} from "types/points";
import { fromPremiumTierToString, parseAvatar } from "utils";
import { Link, Route, Routes } from "react-router-dom";
import Navbar from "components/Navbar";
import Body from "components/Body";
import Footer from "components/Footer";
import { twMerge } from "tailwind-merge";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";

type BulkUserBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  user?: APIUser;
  premium?: APIPremium;
};

type BulkGuildBlob = {
  balances: APIBalance[];
  backpacks: APIBackpack[];
  rewards: APIReward[];
  members: APIMember[];
  guild?: APIGuild;
};

const UserContent: FC<BulkUserBlob> = (props) => {
  const { user, premium, members, balances } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <img
          src={parseAvatar(user?._id, user?.avatar, 32)}
          alt="Avatar"
          className="rounded-full"
        />
        <h4 className="font-bold">
          {user?.username}#{user?.discriminator}
        </h4>
      </div>
      <br />
      {!!premium && <p>Premium {fromPremiumTierToString(premium?.tier)}</p>}
      <div>
        <p className="font-bold mb-1">Members</p>
        <table className="w-full">
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Joined At
              </th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.guildID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.joinedAt}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <p className="font-bold mb-1">Balances</p>
        <table className="w-full">
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Balance
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Username
              </th>
            </tr>
          </thead>
          <tbody>
            {balances?.map((balance) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.guildID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.balance}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.username}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const UserLookup = () => {
  const [editingUserID, setEditingUserID] = useState<string>();

  const [user, setUser] = useState<BulkUserBlob>();
  const token = useAtomValue(pointsTokenAtom);

  return (
    <>
      <form
        className="mt-4"
        onSubmit={async (event) => {
          event.preventDefault();
          if (!editingUserID) return;
          const { data } = await api.get<BulkUserBlob>(
            `/admin/users/${editingUserID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUser(data);
        }}
      >
        <input
          className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-park-50 dark:bg-park-900"
          placeholder="User ID"
          value={editingUserID}
          onChange={(event) => setEditingUserID(event.target.value)}
        />
      </form>
      {user && <UserContent {...user} />}
    </>
  );
};

const GuildContent: FC<BulkGuildBlob> = (props) => {
  const { guild, members, balances } = props;
  return (
    <div className="mt-4">
      <div className="flex items-center gap-2">
        <img
          src={parseAvatar(guild?._id, guild?.icon, 32)}
          alt="Avatar"
          className="rounded-full"
        />
        <h4 className="font-bold">{guild?.name}</h4>
      </div>
      <br />
      <div>
        <p className="font-bold mb-1">Members</p>
        <table>
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Joined At
              </th>
            </tr>
          </thead>
          <tbody>
            {members?.map((member) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.userID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {member.joinedAt}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <p className="font-bold mb-1">Balances</p>
        <table>
          <thead>
            <tr>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Guild ID
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Balance
              </th>
              <th className="border border-park-100 dark:border-park-700 p-2 text-left">
                Username
              </th>
            </tr>
          </thead>
          <tbody>
            {balances?.map((balance) => (
              <tr>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.userID}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.balance}
                </td>
                <td className="border border-park-100 dark:border-park-700 p-2">
                  {balance.username}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const GuildLookup = () => {
  const [editingGuildID, setEditingGuildID] = useState<string>();

  const [guild, setGuild] = useState<BulkGuildBlob>();
  const token = useAtomValue(pointsTokenAtom);

  return (
    <>
      <form
        className="mt-4"
        onSubmit={async (event) => {
          event.preventDefault();
          if (!editingGuildID) return;
          const { data } = await api.get<BulkUserBlob>(
            `/admin/guilds/${editingGuildID}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setGuild(data);
        }}
      >
        <input
          className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-park-50 dark:bg-park-900"
          placeholder="Guild ID"
          value={editingGuildID}
          onChange={(event) => setEditingGuildID(event.target.value)}
        />
      </form>
      {guild && <GuildContent {...guild} />}
    </>
  );
};

const Admin = () => {
  useEffect(() => {
    console.info(
      "Hey, if you found this then you must be snooping around!\nThis page is to help me debug and provide support to users. Adam is the only one who can see this page."
    );
  }, []);

  const isGuildLookup = window.location.pathname.includes("/guilds");

  return (
    <>
      <Navbar />
      <Body>
        <h1 className="mt-8 text-4xl font-bold">Admin Panel</h1>
        <p className="mb-4 opacity-50">
          This page is only visible to the developer and is used to debug and
          provide support to users.
        </p>
        <div className="flex items-center">
          <Link to="/admin">
            <button
              className={twMerge(
                "rounded-xl bg-park-50 rounded-r-none dark:bg-park-900 border border-park-100 dark:border-park-700 py-2 px-3.5 text-sm",
                !isGuildLookup && "bg-park-300 dark:bg-park-700"
              )}
            >
              User Lookup
            </button>
          </Link>
          <Link to="/admin/guilds">
            <button
              className={twMerge(
                "rounded-xl bg-park-50 rounded-l-none dark:bg-park-900 border border-park-100 dark:border-park-700 py-2 px-3.5 border-l-0 text-sm",
                isGuildLookup && "bg-park-300 dark:bg-park-700"
              )}
            >
              Guild Lookup
            </button>
          </Link>
        </div>
        <Routes>
          <Route path="/" element={<UserLookup />} />
          <Route path="guilds/" element={<GuildLookup />} />
        </Routes>
      </Body>
      <Footer />
    </>
  );
};

export default Admin;
