import itemFields, { ItemField } from "config/item";
import { FC, useCallback, useMemo, useState } from "react";
import { APIItem, APIRole } from "types/points";
import { useMap } from "usehooks-ts";
import { useSetAtom } from "jotai";
import { editItemPromptAtom } from "stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import ImageUpload from "components/ImageUpload";
import Slideover from "components/Slideover";
import { twMerge } from "tailwind-merge";
import Select from "components/Select";

type ItemEditModalProps = {
  item: APIItem;
  guildID: string;
  premiumTier?: number;
  roles: APIRole[];
};

const isInObject = <T extends object>(
  obj: T,
  key: string | number | symbol
): key is keyof T => key in obj;

const generateItemFieldMap = (
  item: APIItem
): [string, string | number | boolean | undefined][] => {
  return itemFields.map((field) => {
    if (!isInObject(item, field.id)) return [field.id, undefined];
    const value = item[field.id];
    return [field.id, value];
  });
};

const ItemEditModal: FC<ItemEditModalProps> = (props) => {
  const { item, guildID, premiumTier, roles } = props;
  const [fields, actions] = useMap<
    string,
    string | boolean | number | undefined
  >(generateItemFieldMap(item));
  const [errors, setErrors] = useState<string[]>([]);
  const setEditItemPrompt = useSetAtom(editItemPromptAtom);

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const editItem = useMutation({
    mutationFn: async ({
      guildID,
      item,
    }: {
      guildID: string;
      item: APIItem;
    }) => {
      if (!token) return;
      const res = await api.patch(`/guilds/${guildID}/shop/${item._id}`, item, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const handleChange = useCallback(
    (value: string | number | boolean | undefined, field: ItemField) => {
      const parsed = field.schema.safeParse(value);
      if (parsed.success) {
        actions.set(field.id, value);
        setErrors((errs) => errs.filter((error: string) => error !== field.id));
      } else {
        setErrors((errs) => errs.concat(field.id));
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (
      !fields.get("name") ||
      !fields.get("cost") ||
      !fields.get("description")
    )
      return;

    const editedItem = item;

    if (fields.get("name")) editedItem.name = fields.get("name") as string;
    if (fields.get("cost")) editedItem.cost = fields.get("cost") as number;
    if (fields.get("description"))
      editedItem.description = fields.get("description") as string;
    if (fields.get("role")) editedItem.role = fields.get("role") as string;
    if (fields.get("amount"))
      editedItem.amount = fields.get("amount") as number;
    if (fields.get("banner"))
      editedItem.banner = fields.get("banner") as string;
    if (fields.get("supply"))
      editedItem.supply = fields.get("supply") as number;
    if (fields.get("expire"))
      editedItem.expire = fields.get("expire") as string;
    if (fields.get("message"))
      editedItem.message = fields.get("message") as string;

    editItem.mutate({ guildID, item: editedItem });
    setEditItemPrompt(undefined);
  }, [fields, guildID, editItem, setEditItemPrompt]);

  const handleDismiss = useCallback(() => {
    setEditItemPrompt(undefined);
  }, [setEditItemPrompt]);

  const items = useMemo(() => {
    return (
      roles
        .filter((role) => role.name !== "@everyone" && !role.managed)
        .map((role) => ({
          value: role._id,
          label: role.name,
        })) || []
    );
  }, [roles]);

  return (
    <Slideover opened onClose={handleDismiss} title="Edit Item">
      <div className="flex flex-col gap-2 h-full">
        {itemFields.map((field) => {
          if (!field?.premium || premiumTier) {
            switch (field.type) {
              case "string":
                return (
                  <div key={field.id}>
                    <label className="text-sm font-bold">
                      {field.name}
                      {field.required ? (
                        <span className="text-red-400 ml-1">*</span>
                      ) : null}
                    </label>
                    <p className="text-xs opacity-70 mb-2">
                      {field.description}
                    </p>
                    <input
                      type={field.type}
                      className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                      id={field.id}
                      defaultValue={fields.get(field.id) as string}
                      onChange={(event) =>
                        handleChange(event?.target?.value, field)
                      }
                      minLength={field.min}
                      maxLength={field.max}
                    />
                    {errors.includes(field.id) ? (
                      <small className="text-red-400">
                        A {field.name.toLowerCase()} is required.
                      </small>
                    ) : null}
                  </div>
                );
              case "number":
                return (
                  <div key={field.id}>
                    <label className="text-sm font-bold">
                      {field.name}
                      {field.required ? (
                        <span className="text-red-400 ml-1">*</span>
                      ) : null}
                    </label>
                    <p className="text-xs opacity-70 mb-2">
                      {field.description}
                    </p>
                    <input
                      type={field.type}
                      className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                      id={field.id}
                      defaultValue={fields.get(field.id) as string}
                      onChange={(event) =>
                        handleChange(event?.target?.valueAsNumber, field)
                      }
                      min={field.min}
                      max={field.max}
                    />
                    {errors.includes(field.id) ? (
                      <small className="text-red-400">
                        A {field.name.toLowerCase()} is required.
                      </small>
                    ) : null}
                  </div>
                );
              case "textarea":
                return (
                  <div key={field.id}>
                    <label className="text-sm font-bold">
                      {field.name}
                      {field.required ? (
                        <span className="text-red-400 ml-1">*</span>
                      ) : null}
                    </label>
                    <p className="text-xs opacity-70 mb-2">
                      {field.description}
                    </p>
                    <textarea
                      className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                      id={field.id}
                      defaultValue={fields.get(field.id) as string}
                      onChange={(event) =>
                        handleChange(event?.target?.value, field)
                      }
                      minLength={field.min}
                      maxLength={field.max}
                    />
                    {errors.includes(field.id) ? (
                      <small className="text-red-400">
                        A {field.name.toLowerCase()} is required.
                      </small>
                    ) : null}
                  </div>
                );
              case "select":
                return (
                  <div key={field.id}>
                    <label className="text-sm font-bold">
                      {field.name}
                      {field.required ? (
                        <span className="text-red-400 ml-1">*</span>
                      ) : null}
                    </label>
                    <p className="text-xs opacity-70 mb-2">
                      {field.description}
                    </p>
                    <Select
                      items={items}
                      onChange={(v) => handleChange(v[0] || undefined, field)}
                      defaultValue={[(fields.get(field.id) as string) || ""]}
                    />
                  </div>
                );
              case "image":
                return (
                  <div key={field.id}>
                    <label className="text-sm font-bold">{field.name}</label>
                    <p className="text-xs opacity-70 mb-2">
                      {field.description}
                    </p>
                    <ImageUpload
                      noURL
                      key={field.id}
                      value={fields.get(field.id) as string}
                      onChange={(value) => handleChange(value, field)}
                    />
                  </div>
                );
            }
          }
          return <></>;
        })}
        {!premiumTier && (
          <div>
            Unlock more with premium!{" "}
            <a href="https://points.bot/premium">Learn More.</a>
          </div>
        )}
        <button
          onClick={handleSubmit}
          type="submit"
          className={twMerge(
            "mt-auto p-2 rounded-lg w-full text-white",
            errors.length > 0 ? "bg-red-400" : "bg-brand-500"
          )}
        >
          {errors.length <= 0 ? "Edit Item" : "Errors Found"}
        </button>
      </div>
    </Slideover>
  );
};

export default ItemEditModal;
