import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { RESTGetAPIItemsResult } from "types/points";

const getShop = async (token: string, guildID: string) => {
  return api.get<RESTGetAPIItemsResult | undefined>(`/guilds/${guildID}/shop`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useShop = (guildID?: string) => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["shop", guildID],
    queryFn: async () => {
      if (!token || !guildID) return undefined;
      const res = await getShop(token, guildID);
      return res.data;
    },
    enabled: !!token && !!guildID,
  });
};
