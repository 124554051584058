import { faDownload, faGears, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useCallback } from "react";
import { APIGuild } from "types/points";
import { useAuthState } from "hooks/useAuthState";
import { useNavigate } from "react-router";
import { useLeaderboard } from "hooks/useLeaderboard";
import { useAtomValue, useSetAtom } from "jotai";
import { createItemPromptAtom, pointsTokenAtom } from "stores";
import { api } from "config/network";
import { twMerge } from "tailwind-merge";

type GuildHeaderProps = {
  guild?: APIGuild;
  tab: "leaderboard" | "shop" | "settings";
  permission?: "admin" | "role";
};

const GuildHeader: FC<GuildHeaderProps> = (props) => {
  const { guild, tab, permission } = props;

  const navigate = useNavigate();

  const { data: auth } = useAuthState();
  const { data: leaderboard } = useLeaderboard(guild?._id ?? "");
  const token = useAtomValue(pointsTokenAtom);

  const setItemCreate = useSetAtom(createItemPromptAtom);

  const generateCSV = useCallback(async () => {
    if (!guild) return;
    if (!leaderboard) return;

    const response = await api.get(`/guilds/${guild._id}/leaderboard/csv`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const csvData = response.data as {
      userID: string;
      username: string;
      balance: number;
    }[];

    const csv = csvData.map((member) => {
      return `${member.userID},${member.username},${member.balance}`;
    });

    // make add header
    csv.unshift("id,username,balance");

    // download csv
    const blob = new Blob([csv.join("\n")], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${guild.name}_leaderboard.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [leaderboard, guild]);

  return (
    <div className="my-4 flex flex-col">
      <div className="flex flex-col mt-4">
        <div className="flex items-center gap-4">
          {guild?.icon ? (
            <img
              src={
                guild?.icon
                  ? `https://cdn.discordapp.com/icons/${guild?._id}/${guild?.icon}.png`
                  : undefined
              }
              className="w-8 h-8 rounded-full"
            />
          ) : (
            <div className="w-8 h-8 rounded-full flex items-center justify-center bg-park-500">
              {(guild?.name?.length || 0) > 0 ? guild?.name.at(0) : "?"}
            </div>
          )}
          <p className="text-lg opacity-50 font-light">
            {guild?.name || <div className="h-4 w-36" />}
          </p>
        </div>
      </div>
      <div className="flex mt-4 items-center">
        <h2 className=" text-2xl font-bold mr-auto">
          {tab === "leaderboard"
            ? "Leaderboard"
            : tab === "shop"
              ? "Shop"
              : "Settings"}
        </h2>
        {tab === "shop" ? (
          <button
            className="rounded-xl mr-2 bg-brand-500 text-white py-2 px-3.5 text-sm"
            onClick={() => {
              setItemCreate(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-1" /> Create Item
          </button>
        ) : tab === "leaderboard" &&
          (auth?.premium?.tier === 3 || guild?.premium?.tier === "PLUS") ? (
          <button
            className="rounded-xl mr-2 bg-brand-500 text-white py-2 px-3.5 text-sm"
            onClick={generateCSV}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-1" /> CSV (beta)
          </button>
        ) : null}
        <button
          onClick={() => navigate(`/guilds/${guild?._id}`)}
          className={twMerge(
            "rounded-xl bg-white rounded-r-none dark:bg-park-900 border border-park-100 dark:border-park-700 py-2 px-3.5 text-sm",
            tab === "leaderboard" && "bg-park-50 dark:bg-park-700"
          )}
        >
          Leaderboard
        </button>
        <button
          onClick={() => navigate(`/guilds/${guild?._id}/shop`)}
          className={twMerge(
            "rounded-xl rounded-l-none bg-white dark:bg-park-900 border border-park-100 dark:border-park-700 py-2 px-3.5 border-l-0 text-sm",
            permission === "admin" && "rounded-r-none",
            tab === "shop" && "bg-park-50 dark:bg-park-700"
          )}
        >
          Shop
        </button>
        {permission === "admin" ? (
          <button
            onClick={() => navigate(`/guilds/${guild?._id}/settings`)}
            className={twMerge(
              "rounded-xl bg-white rounded-l-none dark:bg-park-900 border border-park-100 dark:border-park-700 py-2 px-3.5 border-l-0 text-sm",
              tab === "settings" && "bg-park-50 dark:bg-park-700"
            )}
          >
            <FontAwesomeIcon icon={faGears} />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default GuildHeader;
