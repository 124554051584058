import itemFields, { ItemField, itemFieldGroups } from "config/item";
import { FC, useCallback, useMemo, useState } from "react";
import { useMap } from "usehooks-ts";
import { useAtom, useAtomValue } from "jotai";
import { createItemPromptAtom, pointsTokenAtom } from "stores";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "config/network";
import { APIRole, RESTPostAPIItemBody } from "types/points";
import ImageUpload from "components/ImageUpload";
import Slideover from "components/Slideover";
import Stepper from "components/Stepper";
import { twMerge } from "tailwind-merge";
import Select from "components/Select";

type ItemCreationModalProps = {
  guildID: string;
  premiumTier?: number;
  roles: APIRole[];
};

const ItemCreationModel: FC<ItemCreationModalProps> = (props) => {
  const { guildID, premiumTier, roles } = props;
  const [fields, actions] = useMap<
    string,
    string | boolean | number | undefined
  >();
  const [errors, setErrors] = useState<string[]>([]);
  const [createItemPrompt, setCreateItemPrompt] = useAtom(createItemPromptAtom);

  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();

  const createItem = useMutation({
    mutationFn: async ({
      guildID,
      item,
    }: {
      guildID: string;
      item: RESTPostAPIItemBody;
    }) => {
      if (!token) return;
      const res = await api.post(`/guilds/${guildID}/shop`, item, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  const handleSubmit = useCallback(() => {
    if (
      !fields.get("name") ||
      !fields.get("cost") ||
      !fields.get("description")
    )
      return;

    const item = {
      name: fields.get("name")! as string,
      cost: fields.get("cost")! as number,
      description: fields.get("description")! as string,
      amount: (fields.get("amount") as number) || 1,
      banner: fields.get("banner") as string | undefined,
      role: fields.get("role") as string | undefined,
      message: fields.get("message") as string | undefined,
      expire: fields.get("expire") as string | undefined,
      supply: fields.get("supply") as number | undefined,
    };

    createItem.mutate({ guildID, item });
    setCreateItemPrompt(false);
  }, [fields, guildID, createItem, setCreateItemPrompt]);

  const handleDismiss = useCallback(() => {
    setCreateItemPrompt(false);
  }, [setCreateItemPrompt]);

  const handleChange = useCallback(
    (value: string | number | boolean | undefined, field: ItemField) => {
      const parsed = field.schema.safeParse(value);
      if (parsed.success) {
        actions.set(field.id, value);
        setErrors((errs) => errs.filter((error: string) => error !== field.id));
      } else {
        actions.set(field.id, value);
        setErrors((errs) => errs.concat(field.id));
      }
    },
    [actions]
  );

  const items = useMemo(() => {
    return roles
      .filter((role) => role.name !== "@everyone" && !role.managed)
      .map((role) => ({
        value: role._id,
        label: role.name,
      }));
  }, [roles]);

  const [currentStep, setCurrentStep] = useState(0);

  const grouppedFields = useMemo(() => {
    const fields = itemFields.reduce(
      (acc, field) => {
        if (!acc[field.group]) {
          acc[field.group] = [];
        }
        acc[field.group].push(field);
        return acc;
      },
      {} as Record<string, ItemField[]>
    );
    return Object.entries(fields);
  }, []);

  const isLastStep = currentStep === grouppedFields.length - 1;

  const groupErrors = useMemo(() => {
    const errors = grouppedFields.map(([group, itemFields]) => {
      const groupErrors = itemFields.map((field) => {
        const amountField = fields.get("amount");
        const amount = typeof amountField === "number" ? amountField : 0;
        if (field.id === "premium" || (field.id === "role" && amount > 1)) {
          return false;
        }
        if (!field.premium || premiumTier) {
          const parsed = field.schema.safeParse(fields.get(field.id));
          return !parsed.success;
        }
        return false;
      });
      // count the errors in the group
      const errorCount = groupErrors.filter((error) => error).length;

      return {
        group,
        errorCount,
      };
    });
    return errors.reduce(
      (acc, error) => {
        acc[error.group] = error.errorCount;
        return acc;
      },
      {} as Record<string, number>
    );
  }, [fields, grouppedFields, premiumTier]);

  const steps = useMemo(() => {
    return grouppedFields.map(([group, _itemFields]) => {
      const groupDetails = itemFieldGroups[group];
      const groupErrorCount = groupErrors[group];
      return {
        name: groupDetails.name,
        description: groupDetails.description,
        errorCount: groupErrorCount,
      };
    });
  }, []);

  const currentStepFields = grouppedFields[currentStep][1];

  return (
    <Slideover
      title="Create Item"
      opened={createItemPrompt}
      onClose={handleDismiss}
    >
      <div className="flex flex-col gap-2 h-full">
        <Stepper
          steps={steps}
          currentStep={currentStep}
          onStepClick={setCurrentStep}
        />

        <div className="flex flex-col gap-2 flex-grow">
          {currentStepFields.map((field) => {
            const amountField = fields.get("amount");
            const amount = typeof amountField === "number" ? amountField : 0;
            if (field.id === "premium" || (field.id === "role" && amount > 1)) {
              return <div key={field.id} />;
            }
            if (!field.premium || premiumTier) {
              switch (field.type) {
                case "string":
                  return (
                    <div key={field.id}>
                      <label className="text-sm font-bold">
                        {field.name}
                        {field.required ? (
                          <span className="text-red-400 ml-1">*</span>
                        ) : null}
                      </label>
                      <p className="text-xs opacity-70 mb-2">
                        {field.description}
                      </p>
                      <input
                        type={field.type}
                        className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                        id={field.id}
                        defaultValue={fields.get(field.id) as string}
                        onChange={(event) =>
                          handleChange(event?.target?.value, field)
                        }
                        minLength={field.min}
                        maxLength={field.max}
                      />
                      {errors.includes(field.id) ? (
                        <small className="text-red-400">
                          A {field.name.toLowerCase()} is required.
                        </small>
                      ) : null}
                    </div>
                  );
                case "number":
                  return (
                    <div key={field.id}>
                      <label className="text-sm font-bold">
                        {field.name}
                        {field.required ? (
                          <span className="text-red-400 ml-1">*</span>
                        ) : null}
                      </label>
                      <p className="text-xs opacity-70 mb-2">
                        {field.description}
                      </p>
                      <input
                        type={field.type}
                        className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                        id={field.id}
                        defaultValue={fields.get(field.id) as string}
                        onChange={(event) =>
                          handleChange(event?.target?.valueAsNumber, field)
                        }
                        min={field.min}
                        max={field.max}
                      />
                      {errors.includes(field.id) ? (
                        <small className="text-red-400">
                          A {field.name.toLowerCase()} is required.
                        </small>
                      ) : null}
                    </div>
                  );
                case "textarea":
                  return (
                    <div key={field.id}>
                      <label className="text-sm font-bold">
                        {field.name}
                        {field.required ? (
                          <span className="text-red-400 ml-1">*</span>
                        ) : null}
                      </label>
                      <p className="text-xs opacity-70 mb-2">
                        {field.description}
                      </p>
                      <textarea
                        className="w-full p-2 rounded-lg border border-park-100 dark:border-park-700 bg-white dark:bg-park-900 disabled:opacity-50"
                        id={field.id}
                        defaultValue={fields.get(field.id) as string}
                        onChange={(event) =>
                          handleChange(event?.target?.value, field)
                        }
                        minLength={field.min}
                        maxLength={field.max}
                      />
                      {errors.includes(field.id) ? (
                        <small className="text-red-400">
                          A {field.name.toLowerCase()} is required.
                        </small>
                      ) : null}
                    </div>
                  );
                case "select":
                  return (
                    <div key={field.id}>
                      <label className="text-sm font-bold">
                        {field.name}
                        {field.required ? (
                          <span className="text-red-400 ml-1">*</span>
                        ) : null}
                      </label>
                      <p className="text-xs opacity-70 mb-2">
                        {field.description}
                      </p>
                      <Select
                        items={items}
                        onChange={(v) => handleChange(v[0] || undefined, field)}
                        defaultValue={[(fields.get(field.id) as string) || ""]}
                      />
                    </div>
                  );
                case "image":
                  return (
                    <div key={field.id}>
                      <label className="text-sm font-bold">
                        {field.name}
                        {field.required ? (
                          <span className="text-red-400 ml-1">*</span>
                        ) : null}
                      </label>
                      <p className="text-xs opacity-70 mb-2">
                        {field.description}
                      </p>
                      <ImageUpload
                        key={field.id}
                        value={fields.get(field.id) as string}
                        onChange={(value) => handleChange(value, field)}
                      />
                    </div>
                  );
                default:
                  return <></>;
              }
            }
          })}
          {!premiumTier && (
            <div>
              Unlock more with premium!{" "}
              <a href="https://points.bot/premium">Learn More.</a>
            </div>
          )}
        </div>

        <button
          className={twMerge(
            "mt-auto p-2 rounded-lg w-full text-white",
            isLastStep && errors.length > 0 ? "bg-red-400" : "bg-brand-500"
          )}
          onClick={
            isLastStep ? handleSubmit : () => setCurrentStep(currentStep + 1)
          }
          disabled={isLastStep && errors.length > 0}
        >
          {isLastStep
            ? errors.length <= 0
              ? "Create Item"
              : "Errors Found"
            : "Next"}
        </button>
      </div>
    </Slideover>
  );
};

export default ItemCreationModel;
