export enum GuildPremiumTier {
  BASIC = "BASIC",
  PLUS = "PLUS",
}

export enum PremiumTier {
  BASIC = 1,
  PLUS = 2,
  ULTRA = 3,
}

export type Settings = {
  commands: {
    daily: {
      enabled: boolean;
    };
    weekly: {
      enabled: boolean;
    };
    monthly: {
      enabled: boolean;
    };
    yearly: {
      enabled: boolean;
    };
    pay: {
      enabled: boolean;
    };
    add: {
      enabled: boolean;
    };
    set: {
      enabled: boolean;
    };
    subtract: {
      enabled: boolean;
    };
    create: {
      enabled: boolean;
    };
    edit: {
      enabled: boolean;
    };
    delete: {
      enabled: boolean;
    };
    take: {
      enabled: boolean;
    };
    give: {
      enabled: boolean;
    };
    gift: {
      enabled: boolean;
    };
    refund: {
      enabled: boolean;
    };
    buy: {
      enabled: boolean;
    };
    backpack: {
      enabled: boolean;
    };
    leaderboard: {
      enabled: boolean;
    };
    points: {
      enabled: boolean;
    };
    shop: {
      enabled: boolean;
    };
    coinflip: {
      enabled: boolean;
    };
    dice: {
      enabled: boolean;
    };
    bank: {
      enabled: boolean;
    };
    tax: {
      enabled: boolean;
    };
    loan: {
      enabled: boolean;
    };
    rob: {
      enabled: boolean;
    };
  };
  customization: {
    refund: {
      enabled: boolean;
      percentage: number;
    };
    currency: string;
    multiplier: number;
    ignore: boolean;
    logs: {
      channel: string | null;
      webhook_url: string | null;
    };
    embed: {
      logo: string;
      text: string;
      color: string;
    };
    tax: {
      automatic: boolean;
      sales_rate: number;
      income_rate: number;
      due: string;
    };
    loan: {
      interest_rate: number;
      due: number;
      max: number;
    };
    business: {
      enabled: boolean;
      cost: number;
      roles: string[];
    };
  };
  rewards: {
    daily: {
      enabled: boolean;
      min: number;
      max: number;
    };
    weekly: {
      enabled: boolean;
      min: number;
      max: number;
    };
    monthly: {
      enabled: boolean;
      min: number;
      max: number;
    };
    yearly: {
      enabled: boolean;
      min: number;
      max: number;
    };
    booster: {
      enabled: boolean;
      amount: number;
    };
    joined: {
      enabled: boolean;
      amount: number;
    };
    message: {
      enabled: boolean;
      min: number;
      max: number;
    };
  };
  system: {
    moderators: {
      roles: string[];
      members: string[];
    };
  };
  premium: {
    enabled: boolean;
    userID?: string | null;
  };
  apiKey?: string;
};

export type APIChannel = {
  _id: string;
  name: string;
  type: number;
};

export type APIRole = {
  _id: string;
  name: string;
  color: number;
  permissions: string;
  position: number;
  managed: boolean;
};

export type APIGuild = {
  _id: string;
  name: string;
  icon?: string;
  ownerID: string;
  channels: APIChannel[];
  roles: APIRole[];
  currency: string;
  balance: number;
  premium?: {
    tier: GuildPremiumTier;
    userID?: string;
  };
};

export type APIBalance = {
  _id: string;
  userID: string;
  guildID: string;
  balance: number;
  taxed?: number;
  user?: {
    username: string;
    discriminator: string;
    avatar?: string | null;
  };
  username?: string;
};

export type APIUserGuild = {
  _id: string;
  name?: string;
  icon?: string;
  permissions?: string;
  invited?: boolean;
};

export type APIUser = {
  _id: string;
  username?: string;
  discriminator?: string;
  guilds: APIUserGuild[];
  avatar?: string;
  permission: string;
  premium?: {
    tier?: 0 | 1 | 2 | 3;
    guilds?: string[];
    availableGuildSlots?: number;
  };
};

export type APIMember = {
  _id: string;
  guildID: string;
  userID: string;
  user?: {
    username: string;
    discriminator: string;
    avatar?: string | null;
  };
  roles: string[];
  joinedAt: string;
  nick?: string;
  permission?: "admin" | "role";
  balance: number;
  items: {
    id: string;
    amount: number;
    used?: number;
    multiplier?: number;
  }[];
};

export type APIBackpack = {
  _id: string;
  userID: string;
  guildID: string;
  items?: Array<{
    id: string;
    amount: number;
    used?: number;
    multiplier?: number;
  }>;
};

export type APIReward = {
  _id: string;
  userID: string;
  guildID: string;
  daily?: Date;
  weekly?: Date;
  monthly?: Date;
  yearly?: Date;
};

export type APIPremium = {
  _id: string;
  tier: number;
  guilds?: string[];
  note?: string;
};

export type APIItem = {
  _id: string;
  name: string;
  description: string;
  cost: number;
  amount: number;
  role?: string;
  message?: string;
  supply?: number;
  banner?: string;
  expire?: string;
  priority?: number;
  available?: boolean;
};

export type RESTGetAPICurrentUserResult = APIUser;
export type RESTGetAPIGuildResult = APIGuild;
export type RESTGetAPIItemsResult = APIItem[];
export type RESTGetAPIBalancesResult = {
  total: number;
  balances: APIBalance[];
};
export type RESTGetAPIMemberResult = APIMember;

export type RESTPatchAPIItemBody = {
  name?: string;
  description?: string;
  cost?: number;
  amount?: number;
  role?: string;
  message?: string;
  supply?: number;
  expire?: string;
  priority?: number;
};

export type RESTPostAPIItemBody = {
  name: string;
  description: string;
  cost: number;
  amount: number;
  role?: string;
  message?: string;
  supply?: number;
  expire?: string;
  priority?: number;
};

export type RESTPostAPIItemResult = {
  _id: string;
};
