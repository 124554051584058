import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { RESTGetAPIGuildResult } from "types/points";
import { useEffect } from "react";
import { isAxiosError } from "axios";

const getGuild = async (token: string, id: string) => {
  return api.get<RESTGetAPIGuildResult | undefined>(`/guilds/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useGuild = (guildID?: string) => {
  const token = useAtomValue(pointsTokenAtom);

  const q = useQuery({
    queryKey: ["guild", guildID],
    queryFn: async () => {
      if (!token || !guildID) return undefined;
      const res = await getGuild(token, guildID);
      return res.data;
    },
    enabled: !!token && !!guildID,
  });

  const { error } = q;

  useEffect(() => {
    if (error && isAxiosError(error)) {
      if (error.response?.status === 404) {
        window.location.href = `https://discord.com/api/oauth2/authorize?client_id=484395597508509697&permissions=403176657&scope=bot%20applications.commands&guild_id=${guildID}`;
      }
    }
  }, [error]);

  return q;
};
