import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { APIUserGuild } from "types/points";

const getGuilds = async (token: string) => {
  return api.get<APIUserGuild[] | undefined>("/guilds", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useGuilds = () => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["guilds"],
    queryFn: async () => {
      if (!token) return undefined;
      const res = await getGuilds(token);
      return res.data;
    },
    enabled: !!token,
  });
};
