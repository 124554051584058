import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { RESTGetAPICurrentUserResult } from "types/points";

const getAuthState = async (token: string) => {
  return api.get<RESTGetAPICurrentUserResult | undefined>("/auth/state", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useAuthState = () => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["authState", token],
    queryFn: async () => {
      if (!token) return undefined;
      const res = await getAuthState(token);
      return res.data;
    },
    enabled: !!token,
  });
};
