import { FC } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "config/network";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";

type RecoverProps = {
  guildID: string;
  itemID: string;
};

const RecoverButton: FC<RecoverProps> = (props) => {
  const { guildID, itemID } = props;
  const token = useAtomValue(pointsTokenAtom);
  const queryClient = useQueryClient();
  const recoverItem = useMutation({
    mutationFn: async ({
      guildID,
      itemID,
    }: {
      guildID: string;
      itemID: string;
    }) => {
      if (!token) return;
      const res = await api.post(
        `/guilds/${guildID}/shop/${itemID}/recover`,
        undefined,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["currentMember", guildID],
      });
      queryClient.invalidateQueries({
        queryKey: ["shop", guildID],
      });
    },
  });

  return (
    <button
      onClick={() => {
        recoverItem.mutate({
          guildID,
          itemID,
        });
      }}
      type="button"
      className="w-full rounded-lg bg-park-500 text-white font-semibold py-2 hover:bg-park-600 disabled:bg-park-700 disabled:cursor-not-allowed"
    >
      Recover
    </button>
  );
};

export default RecoverButton;
