import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { pointsTokenAtom } from "stores";
import { api } from "config/network";
import { RESTGetAPIMemberResult } from "types/points";

const getMember = async (token: string, guildID: string, memberID: string) => {
  return api.get<RESTGetAPIMemberResult | undefined>(
    `/guilds/${guildID}/members/${memberID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const useMember = (guildID?: string, memberID?: string) => {
  const token = useAtomValue(pointsTokenAtom);

  return useQuery({
    queryKey: ["member", guildID, memberID],
    queryFn: async () => {
      if (!token || !guildID || !memberID) return undefined;
      const res = await getMember(token, guildID, memberID);
      return res.data;
    },
    enabled: !!token && !!guildID && !!memberID,
  });
};
